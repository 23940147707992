<template>
    <div>
        <div class="flex-row" style="margin: 10px 0 5px;">
            <div class="filter-botton" :class="{active: tabIndex == 0}" @click="tabIndex = 0">数据准确率</div>
            <div class="filter-botton" :class="{active: tabIndex == 1}" @click="tabIndex = 1">数据覆盖率</div>
        </div>

        <div v-show="tabIndex == 0">
            <div class="rect-box" v-for="(item,i) in accDetailList" :key="i">
                <div class="box-header">
                    <div class="middle-black-text">{{item.equipCode}}</div>
                    <div class="small-gray-text">调试完成时间：{{item.endTime || '-'}}</div>
                </div>
                <info-table :showHeader="true" :columns="accColumns" :data="item.paramInfos"></info-table>
            </div>
        </div>

        <div v-show="tabIndex == 1">
            <div class="rect-box" v-for="(item,i) in completeDetailList" :key="i">
                <div class="box-header">
                    <div class="middle-black-text">{{item.equipCode}}</div>
                    <!-- <div class="small-gray-text">调试完成时间：{{item.endDate || '-'}}</div> -->
                </div>
                <info-table :showHeader="true" :columns="completeColumns" :data="[].concat(item)"></info-table>
            </div>
        </div>
    </div>
</template>

<script>
import infoTable from '@/components/InfoTable'
import {getAccDetail, getCompleteDetail} from '@/axios/device'
export default {
    components: {infoTable},
    data(){
        return {
            tabIndex: 0,  
            accColumns: [{name: "paramName", text: "考核参数"}, {name: "ruleName", text: "合规规则"}, 
                {name: "resultValue", text: "考核值"}, {name: "result", text: "考核结果", formatter: function(_v){
                    if(_v == "合格"){
                        return `<span style="color: #0e9f6e">${_v}</span>`
                    }else{
                        return `<span style="color: #f05252">${_v}</span>`
                    }
                }}],
            accDetailList: [],
            completeColumns: [{name: "companyCode", text: "工厂"}, {name: "sapTime", text: "入库时间"}, {name: "result", text: "调试在线匹配"}, 
                {name: "debugOnlineMatch", text: "考核结果", formatter: function(_v){
                    if(_v == "合格"){
                        return `<span style="color: #0e9f6e">${_v}</span>`
                    }else{
                        return `<span style="color: #f05252">${_v}</span>`
                    }
                }}],
            completeDetailList: []
        }
    },
    created(){
        let _params = this.$route.query
        getAccDetail(_params).then(res => {
            this.accDetailList = res.content
        })
        getCompleteDetail(_params).then(res => {
            this.completeDetailList = res.content
        })
    }
}
</script>

<style lang="scss" scoped>
    .box-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 5px 0 10px;
    }
</style>